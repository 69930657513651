import React from 'react'
import Logo from '../../assets/images/AIB_LOGO.svg';
import './index.scss';

const Navbar = () => {
    return (
        <div className="navbar_main_container">
            <div className="navbar_container container" data-aos="fade-in" data-aos-duration="1000">
                <div className="left_container">
                    <a href='#home' rel="noreferrer" className="logo_container">
                        <img src={Logo} className="logo" alt="logo" />
                    </a>
                </div>
                <div className="right_container">
                    <div className='right-menu'>
                        <a href='#infrastructure' rel="noreferrer">
                            infrastructure
                        </a>
                        <a className='docs-link' href='#home' rel="noreferrer">
                            docs
                            <div className="tooltip">COMING SOON</div>
                        </a>
                    </div>
                    <div className="button_container btn_main">
                        <a href="https://testnet.allinbets.com/" target='_blank' rel="noreferrer"><button>LAUNCH APP</button></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar